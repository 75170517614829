// get all users
import { functions } from '@/firebase';

const exportPackage = functions.httpsCallable('exportPackage');
const exportQuestions = functions.httpsCallable('exportQuestions');

const startExport = (data: any) =>
  exportPackage(data)
    .then((result) => result.data)
    .catch((error) => console.error(error));

const exportQuestionsPackage = (data: {
  collection: string;
  questions: any[];
  answers?: any[];
  sections?: any[];
  meta?: any;
}) =>
  exportQuestions(data)
    .then((result) => result.data)
    .catch((error) => console.error(error));

export { exportQuestionsPackage, startExport };
