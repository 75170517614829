

















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { shuffle as _shuffle, chain, zipObject, sortBy } from 'lodash';

import { exportHelper } from '@/utils/search';
import { exportQuestionsPackage } from '@/firebase/models/export';

@Component
export default class Export extends Vue {
  @Prop({ default: '' }) collection!: string;
  @Prop({ default: false }) section!: boolean;
  @Prop({ default: false }) all!: boolean;

  loading = true;
  exporting = false;
  initial = true;
  active: any = {
    rank: {},
    series: {},
    procedures: {},
    tags: {},
  };

  collapse = [0, 1];

  name = '';
  facets: any[] = [];

  questions: any = [];
  sections: any = [];

  toggleFacet(facet: string, value: string) {
    this.initial = false;
    if (this.active[facet][value]) {
      delete this.active[facet][value];
    } else {
      this.active[facet][value] = true;
    }
    exportHelper.toggleFacetRefinement(facet, value).search();
  }

  shuffle() {
    if (this.section) {
      for (const item of this.sections) {
        if (item.questions) {
          item.questions = _shuffle(item.questions);
        }
      }
    } else {
      this.questions = _shuffle(this.questions);
    }
  }

  created() {
    exportHelper.on('result', ({ results }) => {
      this.facets = results.disjunctiveFacets;
      this.questions = results.hits;
      this.loading = false;
      if (this.collection === 'LessonQA') {
        this.sections = chain(results.hits)
          .groupBy('procedures')
          .toPairs()
          .map((pair) => zipObject(['name', 'questions'], pair))
          .value();
        this.sections = sortBy(this.sections, ['name']);
      } else if (this.collection === 'TargetedReview' || this.collection === 'TrueFalse') {
        this.questions = sortBy(this.questions, ['series', 'procedures']);
      }
    });

    exportHelper.clearRefinements().addFacetRefinement('collection', this.collection).search();
  }

  async generate() {
    this.exporting = true;

    exportQuestionsPackage({
      collection: this.collection,
      questions: this.questions,
      sections: this.sections,
      meta: this.active,
    })
      .then(({ url }) => {
        this.$notify({
          dangerouslyUseHTMLString: true,
          duration: 20000,
          title: 'Successful Export',
          message: `<a href="${url}">Download</a> the exported package or view previous <a href="/exports">exports</a>`,
          type: 'success',
        });
        this.exporting = false;
      })
      .catch((error) => {
        this.$notify({
          dangerouslyUseHTMLString: true,
          duration: 5000,
          title: 'Error',
          message: `Something went wrong, please try again`,
          type: 'error',
        });
        console.error('url', error);
        this.exporting = false;
      });
  }
}
